import {PropsWithChildren} from "react";
import Gradient from "@/Components/Ui/Gradient";
import {Text} from "@/Components/Catalyst/text";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import {Link} from "@/Components/Catalyst/link";

interface SectionProps extends PropsWithChildren {
  title?: string
  tagLine?: string | null
  useGradient?: boolean
  className?: string;
  ctaUrl?: string;
}

export default function Section(props: SectionProps) {
  const {
    title,
    tagLine,
    useGradient,
    children,
    className,
    ctaUrl,
  } = props;

  const titleTextColor = useGradient ? 'text-white' : 'text-slate-800';
  const tagLineTextColor = useGradient ? 'text-white' : 'text-slate-600';
  const contentTextColor = useGradient ? 'text-white' : 'text-slate-600';
  const bgColor = useGradient ? 'bg-emerald-700' : 'bg-white';

  return (
    <section className={`relative isolate overflow-hidden ${bgColor} ${className}`}>
      {useGradient && <Gradient/>}
      <div className={`mx-auto max-w-7xl px-6 xl:px-0`}>
        {
          // Show Title and TagLine if they are provided
          (title || tagLine) &&
          <div className="mx-auto lg:mx-0">
            {
              // Title
              (ctaUrl || title) &&
              <div className='flex justify-between'>
                <h2 className={`text-4xl font-bold tracking-tight sm:text-6xl ${titleTextColor}`}>
                  {title}
                </h2>

                {
                  // CTA url
                  ctaUrl &&
                  <Link href={ctaUrl} className='mt-4 text-black'>
                    View all <ArrowRightIcon className='h-3 w-3 inline-block'/>
                  </Link>
                }
              </div>
            }

            {
              // Tag Line
              tagLine &&
              <Text className={`mt-2 text-lg leading-8 ${tagLineTextColor}`}>
                {tagLine}
              </Text>
            }

          </div>
        }

        {
          // Show children if they are provided
          children &&
          <div className={`mx-auto mt-6 ${contentTextColor}`}>
            {children}
          </div>
        }
      </div>
    </section>
  );
}
